/* modal창 외부화면 */
.Paymentmodal_Modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.01rem;
  z-index: 999;
}

/* modal창 */
.Paymentmodal_modalBody {
  margin-top: -30vh;
  width: auto;
  height: auto;
  padding: 20px;
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}
.Paymentmodal_firstsection {
  margin-top: 30px;

  width: 310px;
}

.Paymentmodal_firstsection_title {
  font-size: 18px;
  color: black;
  font-weight: 500;
  height: 28px;
  border-bottom: 1px solid #f0f0f0;
}

.Paymentmodal_firstsection_infos {
  margin: 20px 0;
}

.Paymentmodal_firstsection_info {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.Paymentmodal_firstsection_info h4 {
  color: #8c8c8c;
}

.Paymentmodal_firstsection_totalprice {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}

.Paymentmodal_firstsection::after {
  margin-top: 20px;
  content: '';
  position: absolute;
  height: 4px;
  width: 310px;
  background: #f0f0f0;
}

/* secondsection */

.Paymentmodal_secondsection {
  margin-top: 44px;

  width: 310px;
}

.Paymentmodal_secondsection_title {
  font-size: 18px;
  font-weight: 500;
  height: 28px;
  border-bottom: 1px solid #f0f0f0;
}

.Paymentmodal_secondsection_method {
  display: flex;
  margin-top: 25px;
}

.Paymentmodal_secondsection_method p {
  font-size: 16px;
  margin-left: 12px;
}

.Paymentmodal_secondsection_method input[type='radio'] {
  margin-top: -1px;
  vertical-align: middle;
}

.Paymentmodal_secondsection_methods {
  margin-bottom: 10px;
}

.Paymentmodal_secondsection::after {
  content: '';
  position: absolute;
  height: 4px;
  width: 310px;
  background: #f0f0f0;
}

/* thirdsection */

.Paymentmodal_thirdsection {
  margin-top: 30px;

  width: 310px;
}

.Paymentmodal_thirdsection_lastcheck {
  display: flex;
  margin-top: 20px;
}

.Paymentmodal_thirdsection_lastcheck p {
  font-size: 16px;
  margin-left: 10px;
}

.Paymentmodal_thirdsection_lastcheck input[type='radio'] {
  margin-top: -1px;
  vertical-align: middle;
}

.Paymentmodal_thirdsection_lastcheck span {
  font-size: 1.4rem;
  font-weight: 400;
  color: #8c8c8c;
}

.Paymentmodal_thirdsection_lastinfo {
  margin: 25px 0;
  font-size: 12px;
  color: #8c8c8c;
  text-align: center;
}

.Paymentmodal_thirdsection_paybutton {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-size: 18px;
  color: #4e82e5;
  cursor: pointer;
}

/* 간편결제 선택존 */

.Paymentmodal_secondsection_esaypaymethods {
  margin-top: 10px;
  width: 270px;
  margin-left: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.Paymentmodal_secondsection_esaypaymethod {
  margin: 4% 4%;
  box-sizing: border-box;
  display: inline-flex;
  width: 42%;
}

.Paymentmodal_secondsection_esaypaymethod p {
  font-size: 14px;
  margin-left: 4px;
}

.Paymentmodal_secondsection_esaypaymethod input[type='radio'] {
  margin-left: 0;
  margin-top: -1px;
  vertical-align: middle;
}

.Paymentmodal_modaltitle {
  display: flex;
  justify-content: space-between;
}
#Paymentmodal_modaltitle {
  font-weight: 600;
  font-size: 20px;
}

#Paymentmodal_modalCloseBtn {
  border: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 20px;
}

#Paymentmodal_modalCloseBtn:hover {
  cursor: pointer;
}

/* @media (max-width: 750px) {
  .Paymentmodal_modalBody {
    margin-top: 0vh;
  }
} */
