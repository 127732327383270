/* Typography */

.competition-searchzone-option {
  font-size: 16px;
  letter-spacing: -0.1em;
  color: #b1b1b1;
}
#competition-searchzone-black {
  color: black;
}

.each-competition-body-poster h1 {
  letter-spacing: -0.12rem;
  font-size: 2.8rem;
  font-weight: 400;
  color: #ffffff;
  z-index: 2;
}

.each-competition-body-poster h1 span {
  font-size: 2rem;
  margin-left: 0.2rem;
  letter-spacing: -0.12rem;
  color: #ffffff;
  z-index: 2;
}

.each-competition-body-desc-top p {
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: -0.12rem;
  line-height: 1.1;
  max-width: calc(100% - 18px);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.each-competition-body-desc-middle p {
  /* max-width: 90%; */
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.12rem;
  line-height: 18px;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #696969;
}

/* Layout */

.competition-schedule-wrapper {
  width: auto;
  max-width: 1200px;
  min-height: 84vh;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.competition-searchzone {
  /* height: 80px; */
  margin-top: 10px;
  display: flex;
  align-items: end;
}

.competition-searchzone-searchbar {
  margin-left: auto;
  margin-bottom: 1.2rem;
  position: relative;
}

.competition-list {
  height: 74vh;
  border-top: 1px solid #e7eaf6;
  padding-top: 2.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.competition-list::-webkit-scrollbar {
  width: 6px;
}
.competition-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: gainsboro;
}

.competition-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; /* 이거중요 */
}

.competition-col {
  width: 33.3%;
  flex-shrink: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  height: 162px;
}

/*카드 비활성화*/
#competitionCardGray-all p,
#competitionCardGray-all button {
  color: #8c8c8c;
  cursor: default;
}

#competitionCardGray-button button {
  color: #8c8c8c;
  cursor: default;
}

/*Component*/

.competition-searchzone-option {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  width: 120px;
  height: 42px;
  border: 1px solid #adadad;
  border-radius: 10px;

  padding: 0 1.4rem;
  margin-right: 0.8rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
}

.competition-searchzone-option img {
  margin-left: auto;
}

.competition-searchzone-option ul {
  left: 0;
  top: 40px;
  position: absolute;
  width: 120px;
  height: 300px;
  background: #fcfcfc;
  border: 1px solid #000000;
  border-radius: 10px;

  box-sizing: border-box;
  overflow-y: scroll;
  z-index: 11;
}

.competition-searchzone-option ul::-webkit-scrollbar {
  width: 0px;
}

.competition-searchzone-option li {
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  letter-spacing: -0.1em;
  color: #828282;
  padding-left: 1.2rem;
  /* background: #FCFCFC; */
}
.competition-searchzone-option li:hover {
  background-color: #f5f5f5;
}

.competition-searchzone-searchbar input {
  width: 240px;
  height: 42px;
  box-sizing: border-box;
  border: 1px solid #adadad;
  border-radius: 50px;
  font-size: 1.6rem;
  padding-top: 4px;
  padding-left: 1.4rem;
  padding-right: calc(1.4rem + 24px);
}
.competition-searchzone-searchbar input::placeholder {
  font-size: 1.6rem;
  letter-spacing: -0.1em;
  color: #b1b1b1;
}

/* 검색 드랍다운 활성화 스타일 */
#competition-searchzone-active {
  color: #000000;
  font-weight: 500;
  background-color: #f5f5f5;
}

.competition-searchzone-searchbar img {
  position: absolute;
  right: 1.4rem;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.each-competition-tag {
  height: 20px;
  margin-bottom: 2px;
  display: flex;
}

.each-competition-tag-red {
  background: #fff3f3;
  color: #ff5f5f;
  font-size: 14px;
  letter-spacing: -0.1em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 3px;
  margin-right: 5px;
}

.each-competition-tag-red p {
  /*수직정렬 맞췄지만 p태그안에 수직이 뭔가 안맞아서 인위적으로 p태그를 이동시킴 */
  margin-top: 2px;
}

.each-competition-tag-blue {
  background: #f3f7ff;
  color: #4e82e5;
  font-size: 14px;
  letter-spacing: -0.1em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 3px;
  margin-right: 5px;
}

.each-competition-tag-blue p {
  /*수직정렬 맞췄지만 p태그안에 수직이 뭔가 안맞아서 인위적으로 p태그를 이동시킴 */
  margin-top: 2px;
}

.each-competition-tag-gray {
  background: #f0f0f0;
  color: #8c8c8c;
  font-size: 14px;
  letter-spacing: -0.1em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 3px;
  margin-right: 5px;
}

.each-competition-tag-gray p {
  /*수직정렬 맞췄지만 p태그안에 수직이 뭔가 안맞아서 인위적으로 p태그를 이동시킴 */
  margin-top: 2px;
}

.each-competition-tag-purple {
  background: #fbeaff;
  color: #e441ff;
  font-size: 14px;
  letter-spacing: -0.1em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 3px;
  margin-right: 5px;
}

.each-competition-tag-purple p {
  /*수직정렬 맞췄지만 p태그안에 수직이 뭔가 안맞아서 인위적으로 p태그를 이동시킴 */
  margin-top: 2px;
}

.each-competition-tag-green {
  background: #eaffeb;
  color: #26a312;
  font-size: 14px;
  letter-spacing: -0.1em;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 3px;
  margin-right: 5px;
}

.each-competition-tag-green p {
  /*수직정렬 맞췄지만 p태그안에 수직이 뭔가 안맞아서 인위적으로 p태그를 이동시킴 */
  margin-top: 2px;
}

.each-competition-body {
  display: flex;
  height: 140px;
  border: 1px solid #d9e0e4;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.each-competition-body-poster {
  min-width: 108px;
  position: relative;

  cursor: pointer;
}

.each-competition-body-poster img {
  width: 100%;
  height: 100%;
}

.each-competition-body-poster-block {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: 10px 0 0 10px;
  z-index: 1;
}

.each-competition-body-poster h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.each-competition-body-desc {
  min-width: calc(100% - 108px);
  box-sizing: border-box;
  padding-left: 8px;
}

.each-competition-body-desc-top {
  height: 70px;
  display: flex; /* 수직 가운데정렬을 위한 2중콤보 */
  align-items: center;
  cursor: pointer;
}
/* .each-competition-body-desc-top p:hover {
  font-weight: 500;
} */

.each-competition-body-desc-middle {
  height: 20px;
  display: flex; /* 수직 가운데정렬을 위한 2중콤보 */
  align-items: center;
}

/* 대회카드 아래 (신청기간 + 좋아요) */
.each-competition-body-desc-bottom {
  width: 96%;
  padding: 14px 0px;
  margin-top: 10px;
  /* height: 50px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: -0.7px;

  border-top: 1px solid #f3f3f3;
}

.each-competition-body-applyDate {
  font-size: 14px;
  color: #7c7c7c;
  display: flex;
}

.each-competition-body-applyDate h3 {
  margin-right: 6px;
}

.each-competition-body-bottom-right {
  display: flex;
}

/* 좋아요 수 */
.each-competition-body-like {
  cursor: pointer;
  display: flex;
}

.each-competition-body-like p {
  color: #ff5f5f !important;
  text-align: center;
  cursor: pointer !important;
  font-size: 14px;
}

.each-competition-body-like img {
  width: 14px;
  margin-right: 2px;
}

/* 방문자 수 */
.each-competition-body-view {
  display: flex;
  margin-right: 8px;
}

.each-competition-body-view p {
  font-size: 14px;
  color: #595959 !important;
}

.each-competition-body-view img {
  width: 14px;
  margin-right: 2px;
}

/* 필터 체크박스 디자인 */
.competition-filter {
  /* margin-top: 2px; */
  margin-bottom: 10px;
  height: 40px;

  overflow: auto;
  white-space: nowrap;
}
.competition-filter::-webkit-scrollbar {
  display: none;
}

.competition-filter label {
  cursor: pointer;
  margin-right: 8px;
}

.competition-filter label input[type='checkbox'] {
  display: none;
}

.competition-filter label span {
  position: relative;
  display: inline-block;
  padding: 10px 12px;
  color: #999999;
  border-radius: 50px;
  border: 1px solid #e4e4e4;
  font-size: 15px;
  transition: 0.1s;
  user-select: none;
  overflow: hidden;
}

/* 신청가능 */
.filter-active {
  background-color: #f3f7ff;
}
.competition-filter
  label
  input[type='checkbox'][value='active']:checked
  ~ span {
  border: 1px solid #4e82e5;
  color: #4e82e5;
}

/* 얼리버드 */
.filter-early {
  background-color: #fff3f3;
}
.competition-filter label input[type='checkbox'][value='early']:checked ~ span {
  border: 1px solid #ff5f5f;
  color: #ff5f5f;
}

/* 간편결제 */
.filter-easypay {
  background-color: #faedff;
}
.competition-filter
  label
  input[type='checkbox'][value='easypay']:checked
  ~ span {
  border: 1px solid #be4ee5;
  color: #be4ee5;
}

/* 내 좋아요 */
.filter-likes {
  background-color: #ffffff;
}
.competition-filter label .filter-likes {
  padding: 9px 12px;
}

.filter-likes img {
  width: 14px;
  height: 14px;
  transition: 0.1s;
  opacity: 0.5;
}
.competition-filter label input[type='checkbox'][value='likes']:checked ~ span {
  border: 1px solid #101010;
  color: #101010;
}
.competition-filter
  label
  input[type='checkbox'][value='likes']:checked
  ~ span
  img {
  opacity: 1;
}

@media (max-width: 1049px) {
  /* Layout */
  .competition-schedule-wrapper {
    max-width: 768px;
  }

  .competition-col {
    width: 50%;
  }
}

@media (max-width: 750px) {
  /* Typography */

  .competition-searchzone-option {
    font-size: 16px;
  }

  /* Layout */

  .competition-schedule-wrapper {
    max-width: 650px;
    height: 88vh;
  }
  .competition-col {
    width: 100%;
  }
  .competition-list {
    height: 78vh;
  }

  /* component */

  .competition-searchzone-searchbar input {
    height: 38px;
    width: 170px;
    padding-left: 1rem;
    padding-right: calc(1rem + 24px);
    padding-top: 2px;
  }
  .competition-searchzone-searchbar input::placeholder {
    font-size: 1.6rem;
  }

  .competition-searchzone-searchbar img {
    right: 1rem;
  }

  .competition-searchzone-option {
    padding: 0 1rem;
    height: 38px;
    width: 85px;
    margin-right: 0.4rem;
  }

  .competition-searchzone-option ul {
    width: 85px;
  }

  .competition-filter label {
    margin-right: 6px;
  }

  /* 모바일 카드 크기 줄여보기 */
  .competition-col {
    margin-bottom: 0px;
  }

  .each-competition-body {
    height: 120px;
  }
  .each-competition-body-poster {
    min-width: 96px;
    max-width: 96px;
  }

  .each-competition-body-poster h1 {
    font-weight: 500;
    letter-spacing: -0.14rem;
    font-size: 26px;
  }

  .each-competition-body-poster h1 span {
    font-size: 18px;
  }

  .each-competition-body-desc-top {
    height: 54px;
  }

  .each-competition-body-desc-top p {
    font-size: 18px;
  }

  .each-competition-body-desc-bottom,
  .each-competition-body-desc-middle {
    margin-bottom: 5px;
  }

  .each-competition-body-desc-middle p {
    font-size: 14px;
  }

  .each-competition-body-desc-middle {
    height: 20px;
  }

  .each-competition-body-desc-bottom {
    padding: 3px 0px;
    height: 30px;
  }
}
