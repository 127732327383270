/* UA reset */

/* Typography */

:root {
  font-size: 62.5%; /* 10px */
}

/* Layout */

.Profilesection_wrapper {
  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem 0 2rem;
  box-sizing: border-box;
  display: flex;
}

@media (max-width: 1049px) {
  /* Layout */

  .Profilesection_wrapper {
    max-width: 768px;
  }
}

@media (max-width: 750px) {
  .Profilesection_wrapper {
    max-width: 650px;
  }
}
