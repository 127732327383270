/* Typography */
.CompetitionApplyForm-top-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.CompetitionApplyForm-top-table-standard li {
  font-size: 1.6rem;
  color: #8c8c8c;
}

.CompetitionApplyForm-top-table-item li {
  font-size: 2rem;
}

.CompetitionApplyForm-middle-function-re p {
  font-size: 1.4rem;
}

.CompetitionApplyForm-middle-function-complete p {
  font-size: 1.4rem;
}

.CompetitionApplyForm-middle-info {
  font-size: 1.5rem;
}

.CompetitionApplyForm-middle-info-checkmessage {
  font-size: 1.5rem;
}

/* Layout */

.CompetitionApplyForm-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: 84vh;
  box-sizing: border-box;
  /* border: 2px blue solid; */
}

/* .CompetitionApplyForm-top{
} */

/*Component*/

.CompetitionApplyForm-top-title {
  text-align: center;
  padding: 4vh 0;
}

.CompetitionApplyForm-top-table {
  display: flex;
  flex-direction: column;
}

.CompetitionApplyForm-top-table-standard {
  display: flex;
  margin: 0 auto;
  /* border: 1px solid red; */
  width: 60%;
  margin-bottom: 15px;
}

.CompetitionApplyForm-top-table-standard li {
  width: 20%;
  text-align: center;
}

.CompetitionApplyForm-top-table-item {
  display: flex;
  margin: 0 auto;
  /* border: 1px solid red; */
  width: 60%;
  margin-bottom: 15px;
  position: relative;
}

.CompetitionApplyForm-top-table-item li {
  width: 20%;
  text-align: center;
}

.CompetitionApplyForm-top-table-item img {
  position: absolute;
  right: 0;
  transform: translate(100%, 0);
}

.CompetitionApplyForm-middle {
  width: 60%;
  margin: 0 auto;
  margin-top: 6vh;
  margin-bottom: 44px;
  background-color: #f8fbff;

  border-radius: 15px;
}

.CompetitionApplyForm-middle-function {
  display: flex;
  justify-content: space-between;
}

.CompetitionApplyForm-middle-function-re {
  text-align: center;

  margin-left: 10px;
  margin-top: 10px;
}

.CompetitionApplyForm-middle-function-complete {
  text-align: center;

  margin-right: 10px;
  margin-top: 10px;
}

.CompetitionApplyForm-middle-function-re p {
  margin-top: 1vh;
}

.CompetitionApplyForm-middle-function-complete p {
  margin-top: 1vh;
}

.CompetitionApplyForm-middle-option {
  width: 80%;
  margin: 0 auto;
  margin-top: 3vh;
  /* display: flex; */
}

.CompetitionApplyForm-middle-option li {
  display: inline-block;
  width: 40%;
  text-align: center;
  margin: 1vh 5%;
  padding: 1vh 2px;
  font-size: 1.8rem;
  box-sizing: border-box;
  border: 2px solid #000000;

  border-radius: 15px;
  cursor: pointer;
}

.CompetitionApplyForm-middle-info {
  text-align: center;
  margin-top: 8vh;
  padding-bottom: 1vh;
}

.CompetitionApplyForm-middle-info-checkmessage {
  text-align: center;
  padding-bottom: 5vh;
  margin-top: 4vh;

  font-size: 1.6rem;
  line-height: 2rem;
}
.CompetitionApplyForm-middle-info-checkmessage span {
  color: #4e82e5;
}

.CompetitionApplyForm-bottom {
  text-align: center;
}

.CompetitionApplyForm-bottom-payment {
  border: 1px solid #f1f1f1;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: #4e82e6;
  background: #ffffff;
  width: 110px;
  height: 46px;
  min-width: 110px;
  min-height: 46px;
  margin-top: 3vh;
  font-size: 1.8rem;
  cursor: pointer;
}

@media (max-width: 750px) {
  /* Layout */

  .CompetitionApplyForm-wrapper {
    min-height: 88vh;
  }

  .CompetitionApplyForm-top-table-standard {
    width: 90%;
  }

  .CompetitionApplyForm-top-table-item {
    width: 90%;
  }

  /*Component*/

  .CompetitionApplyForm-middle {
    width: 80%;
  }

  .CompetitionApplyForm-top-table-item img {
    left: 0;
    transform: translate(-60%, 0);
  }
  /* Typography */

  .CompetitionApplyForm-top-table-standard li {
    font-size: 1.3rem;
    color: #8c8c8c;
  }

  .CompetitionApplyForm-top-table-item li {
    font-size: 1.7rem;
  }

  .CompetitionApplyForm-bottom-payment {
    font-size: 1.6rem;
    width: 96px;
    height: 40px;
    min-width: 96px;
    min-height: 40px;
  }
}
