/* UA reset */

/* Typography */

:root {
  font-size: 62.5%; /* 10px */
}

/* Layout */

.UserInfo_right {
  width: 68%;
  height: 84vh;
  margin-left: 4%;
  overflow-y: auto;
  position: relative;
}

.UserInfo_right h2 {
  font-size: 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  box-sizing: border-box;
  font-weight: 500;
}

.UserInfo_infoBox {
  border-bottom: 1px solid #e9e9e9;
  width: 98%;
  margin-bottom: 16px;
  box-sizing: border-box;
}
#UserInfo_changewrap .UserInfo_infoBox {
  border-bottom: none;
  height: 5.8rem;
}

#UserInfo_changewrap .UserInfo_infoBox {
  margin-bottom: 16px;
}

.UserInfo_boxsRead .UserInfo_infoBox {
  height: 5.8rem;
}

.UserInfo_infoBox input {
  padding: 0.8rem;
  font-size: 1.6rem;
}

.UserInfo_infoBox .UserInfo_phoneNumber {
  -webkit-appearance: none;
  margin: 0;
}

.UserInfo_infoBox p {
  margin-top: 1.4rem;
  font-size: 1.8rem;
}

.UserInfo_infoBox span {
  color: #9f9f9f;
  font-size: 1.4rem;
}

.UserInfo_flexbox {
  display: flex;
}

.UserInfo_flexbox span {
  margin-top: 3.2rem;
  margin-left: 1rem;
  font-size: 1.2rem;
}

.UserInfo_beltSeclet {
  margin-top: 1.2rem;
}

.UserInfo_beltSeclet select {
  padding: 0.8rem;
  font-size: 1.6rem;
  width: 200px;
}

.UserInfo_beltSeclet select:hover {
  cursor: pointer;
}

.UserInfo_updateBtn {
  background-color: #ffffff;
  color: #7e7e7e;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  box-sizing: border-box;
  width: 9rem;
  min-width: 9rem;
  height: 4rem;
  min-height: 4rem;
  font-size: 1.6rem;
  display: inline;
  font-weight: 500;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.4rem;
}

.UserInfo_updateBtn:hover {
  cursor: pointer;
}

.UserInfo_saveBtn {
  color: #4e82e5;
}

.UserInfo_genderCategory {
  display: flex;
  padding-top: 0.2rem;
}

.UserInfo_genderCategory input {
  margin-right: 0.6rem;
}

.UserInfo_genderCategory span {
  margin-right: 6rem;
  color: black;
  font-size: 1.6rem;
}

@media (max-width: 1049px) {
  /* Layout */
  .UserInfo_right {
    width: 100%;
    margin-left: 0%;
  }

  .UserInfo_right .UserInfo_Boxs {
    text-align: left;
  }

  .UserInfo_right h2 {
    text-align: left;
    font-size: 2.6rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}

@media (max-width: 750px) {
  .UserInfo_right {
    height: 88vh;
  }
}
