/*  typography */

.PaymentFail-wrapper h2 {
  font-weight: 700;
  font-size: 2.8rem;
  letter-spacing: -0.1em;
  color: #ff5f5f;
  margin-top: 40px;
}

.PaymentFail-wrapper h3 span {
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: -0.1em;
}

.PaymentFail-wrapper h3 {
  font-weight: 400;
  font-size: 2.2rem;
  letter-spacing: -0.1em;
  margin-top: 30px;
}

.PaymentFail-wrapper-button button {
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: -0.1em;
  color: #8c8c8c;
  cursor: pointer;
}

#PaymentFail-blue-button {
  color: #4e82e5;
}

/* component */

.PaymentFail {
  min-height: 84vh;
  box-sizing: border-box;
  padding-top: 10vh;
}

.PaymentFail-wrapper {
  width: 80%;
  height: 394px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.PaymentFail-wrapper-button button {
  width: 220px;
  height: 50px;
  margin-top: 40px;

  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  /* 버튼그림자 */
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

@media (max-width: 750px) {
  .PaymentFail {
    min-height: 88vh;
  }

  .PaymentFail-wrapper {
    /* width: 320px; */
    height: 273px;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .PaymentFail-wrapper img {
    width: 110px;
    height: 110px;
  }

  .PaymentFail-wrapper-button button {
    width: 40%;
    min-width: 110px;
    height: 44px;
    margin-left: 4px;
    margin-right: 4px;
  }

  /*  typography */

  .PaymentFail-wrapper h2 {
    font-size: 2.4rem;
    margin-top: 20px;
  }

  .PaymentFail-wrapper h3 {
    font-size: 1.4rem;
    margin-top: 20px;
  }

  .PaymentFail-wrapper h3 span {
    font-size: 1.4rem;
  }

  .PaymentFail-wrapper-button button {
    font-size: 1.6rem;
  }
}
