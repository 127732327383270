.MainScroll_navWrapper {
  position: relative;
  z-index: 999;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #ededed;
}

.MainScroll_nav {
  display: flex;
  height: 72px;
  line-height: 72px;
  z-index: 999;
  color: var(--white-color);
  max-width: 821px;
  /* background-color: white; */
  /* padding: 0 30rem; */
  box-sizing: border-box;
  padding: 0 6px;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* border-bottom: 1px solid #EDEDED; */
}

/* nav 바 */
.MainScroll_logo {
  font-family: 'Anton', sans-serif;
  font-size: var(--font-size-navlogo);
  margin-right: auto;
  cursor: pointer;
}

.MainScroll_list {
  display: flex;
  letter-spacing: -0.12rem;
  font-weight: 400;
  position: relative;
}

.MainScroll_bar {
  display: block;
  width: 32px;
  height: 2.5px;
  margin: 8px auto;
  /* border-radius: 25px; */
  /* -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; */
  /* background-color: #888888; */
}

.MainScroll_menu li {
  margin-right: 5rem;
  cursor: pointer;

  font-weight: 300;
}

.MainScroll_menu {
  display: flex;
  font-size: var(--font-size-navlist);
}

.MainScroll_profile {
  margin: auto 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  z-index: 888;
}

/* 메뉴 햄버거 */
.MainScroll_hamburger {
  display: none;
  cursor: pointer;
  z-index: 999;
}

.MainScroll_hamburgerblack {
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 900;

  display: none;
}

.MainScroll_hamburgermenu {
  background-color: white;
  color: black;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  display: none;
  padding-top: 72px;
  z-index: 900;
}

.MainScroll_hamburgerlist li {
  font-size: 20px;
  font-weight: 300;
  padding-left: 50px;
  border-bottom: 1px solid #f9f9f9;
  border-top: 1px solid #f9f9f9;
  cursor: pointer;
}

.MainScroll_hamburgerlist li:last-child {
  border-top: none;
}

.MainScroll_hamburgerlist li:hover {
  background-color: #f9f9f9;
}

/* 프로필 드랍다운 */
#profile-dropdown {
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 14rem;
  top: 72px;
  border-radius: 5px;
  z-index: 999;
}

#profile-dropdown li {
  color: black;
  width: 110px;
  height: 46px;
  font-size: 1.5rem;
  text-align: left;
  display: flex; /* 수직정렬을 위한 3종세트 display, align-items, justify-content */
  align-items: center;
  justify-content: left;
  padding-left: 14px;
  margin-right: 0px;
}

#profile-dropdown li:hover {
  background-color: #eeeeee;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@media (max-width: 1049px) {
  .MainScroll_nav {
    padding: 0 10px;
    max-width: 1049px;
  }

  .MainScroll_hamburger {
    display: inline-block;
    margin: auto 0;
    /* position: absolute; */
  }

  .MainScroll_hamburgerblack.active {
    display: inline-block;
  }

  .MainScroll_hamburgermenu.active {
    /* animation: fadeInLeft 0.4s; */
    display: block;
  }

  .MainScroll_hamburger.active .MainScroll_bar:nth-child(2) {
    opacity: 0;
    background-color: #888888;
  }

  .MainScroll_hamburger.active .MainScroll_bar:nth-child(1) {
    transform: translateY(10.5px) rotate(45deg);
  }

  .MainScroll_hamburger.active .MainScroll_bar:nth-child(3) {
    transform: translateY(-10.5px) rotate(-45deg);
  }

  .MainScroll_logo {
    margin: 0 auto;
  }

  .MainScroll_menu li {
    display: none;
  }

  #profile-dropdown {
    left: -8rem;
  }
}
