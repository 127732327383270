/* UA reset */

/* Typography */

:root {
  font-size: 62.5%; /* 10px */
}

.ProfileInfo_competition_Left h2 {
  letter-spacing: -0.12em;
  color: #8c8c8c;
  font-size: 1.4rem;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
}

/* Layout */

.ProfileInfo_wrapper {
  width: auto;
  min-height: 84vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem 0 2rem;
  box-sizing: border-box;
  letter-spacing: -0.14rem;
}

.ProfileInfo_title {
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
}

.ProfileInfo_title a:hover {
  cursor: pointer;
}

.ProfileInfo_title img {
  margin-right: 1rem;
}

.ProfileInfo_competition {
  display: flex;
  padding-bottom: 3.6rem;
  /* height: 35%;  */
}

.ProfileInfo_competition_Left {
  width: 14%;
  height: 188px;
}

.ProfileInfo_competition_Left img {
  width: 100%;
  height: 100%;
}

.ProfileInfo_competition_Right {
  width: 80%;
}

.ProfileInfo_competition_date {
  padding-left: 2.5rem;
  padding-bottom: 1.4rem;
  display: flex;
}

.ProfileInfo_user {
  padding-bottom: 3rem;
}

.ProfileInfo_userInfo_Wrap {
  display: flex;
}

.ProfileInfo_userInfo {
  display: flex;
}
.ProfileInfo_userInfo_team {
  padding-right: 4.6rem;
}

/* 오칸 테이블 */
#ProfileInfo-bottom {
  text-align: left;
  margin-left: 0;
}

/* Components */
.Profilesection_lastCompetiton {
  color: #8c8c8c;
}

.ProfileInfo_title h2 {
  font-size: 3rem;
  font-weight: 500;
}

.ProfileInfo_competition_date h3 {
  width: 14rem;
  font-size: 1.8rem;
  color: #8c8c8c;
  padding-right: 0.6rem;
}

.ProfileInfo_competition_date p {
  width: 80%;
  font-size: 2rem;
  word-break: normal;
}

.ProfileInfo_user h2 {
  font-size: 1.8rem;
  font-weight: 500;
  padding-bottom: 1.4rem;
}

.ProfileInfo_userInfo h3 {
  font-size: 1.8rem;
  color: #8c8c8c;
  padding-right: 1.6rem;
}

.ProfileInfo_userInfo p {
  font-size: 2rem;
}

.ProfileInfo_userList h2 {
  font-size: 1.8rem;
  font-weight: 500;
  padding-bottom: 1.4rem;
}

/* 반응형 */
@media (max-width: 1049px) {
  /* Layout */

  .ProfileInfo_wrapper {
    max-width: 768px;
  }

  .ProfileInfo_competition_Left {
    width: 20%;
  }
}

@media (max-width: 750px) {
  .ProfileInfo_wrapper {
    max-width: 650px;
    min-height: 88vh;
  }

  .ProfileInfo_competition_date {
    padding-left: 1.4rem;
    padding-bottom: 1rem;
  }

  .ProfileInfo_competition_date h3 {
    width: 8.4rem;
    font-size: 1.4rem;
  }

  .ProfileInfo_competition_date p {
    font-size: 1.6rem;
  }

  .ProfileInfo_userInfo h3 {
    font-size: 1.4rem;
  }

  .ProfileInfo_userInfo p {
    font-size: 1.6rem;
  }

  .ProfileInfo_userInfo_team {
    padding-right: 2.6rem;
  }

  .ProfileInfo_competition_Left {
    min-width: 11rem;
    height: 138px;
  }

  .ProfileInfo_competition_Left h2 {
    font-size: 1.2rem;
  }
}
