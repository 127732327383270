/* Typography */

:root {
  font-size: 62.5%; /* 10px */
}

.company-wrapper h3 {
  font-size: 1.6rem;
}

.company-wrapper {
  padding-top: 24px;
}

.footer-info {
  color: #767676;
  font-size: 1.2rem;
}

.footer-wrapper {
  color: #767676;
}

.company-menu-list {
  font-size: 1.4rem;
  cursor: pointer;
}

/* Layout */
.Footer-wrap {
  padding-top: 44px;
  background-color: white;
}

.footer-wrapper {
  border-top: 1px solid #efefef;

  width: auto;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  padding: 0 5rem;
  /* margin-top: 8vh; */
  z-index: 899;
  position: relative;
}

.company-menu {
  margin-left: auto;
}

.company-menu-list {
  display: flex;
  margin-top: 9rem;
}

.company-menu-list li {
  padding: 0 2em;
  position: relative;
}

.company-wrapper h3 {
  color: #525252;
  line-height: 3rem;
}

.company-wrapper p {
  font-size: 1.4rem;
  line-height: 2rem;
}

.footer-info {
  text-align: center;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.footer-info p {
  padding: 0.2em 0;
}

.company-menu-list li::after {
  content: ' ';
  display: inline-block;
  width: 1.5px;
  height: 100%;
  background-color: #888888;
  position: absolute;
  right: 0;
  bottom: 1px;
}

#company-menu-list-last::after {
  display: none;
}

@media (max-width: 1049px) {
  /* Typography */

  /* Layout */

  .company-menu {
    width: 100%;
    /* border: 2px solid green; */
  }

  .company-menu-list li {
    margin-top: 24px;
    width: 20%;
    text-align: center;
  }

  .footer-wrapper {
    max-width: 768px;
    margin-top: 8vh;
  }

  .company-menu {
    margin: 0 auto;
  }

  .company-menu-list {
    margin: auto 0;
  }

  .company-wrapper {
    display: none;
  }
}

@media (max-width: 750px) {
  .Footer-wrap {
    height: 6vh;
    padding-top: 8vh;
    padding-bottom: 2vh;
  }
  /* Typography */
  .company-menu-list {
    font-size: 1.4rem;
    color: #767676;
    letter-spacing: -0.1em;
  }

  /* Layout */

  .footer-wrapper {
    max-width: none;
    width: 100%;
    margin: 0 auto;
    /* margin-top: 8vh;
    margin-bottom: 2vh; */
    padding: 0;
  }

  .company-menu {
    width: auto;
    /* border: 2px solid green; */
    margin: 0 auto;
  }

  .company-menu-list li {
    width: auto;
    text-align: center;
    padding: 0 10px;
  }

  .footer-info {
    display: none;
  }
}
