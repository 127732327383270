/* UA reset */

/* Typography */

:root {
  font-size: 62.5%; /* 10px */
}

/* Layout */

.ProfileTap_wrapper {
  /* border: 2px solid yellow; */
  width: 28%;
  height: 84vh;
  overflow-y: auto;
}

.ProfileTap_welcome {
  /* border: 2px solid red; */
  height: 18%;
  /* margin-top: auto;
    margin-bottom: auto; */
  position: relative;
}

.ProfileTap_competitionCount {
  /* border: 1px solid green; */
  height: 20%;
  display: flex;
  align-items: center;
}

.ProfileTap_information {
  /* border: 1px solid blue; */
  height: 50%;
}

/* Components */

.ProfileTap_welcome-center {
  /* border: 2px solid black; */
  position: absolute;
  top: 100%;
  transform: translateY(-120%);
}

.ProfileTap_welcome-center p {
  font-size: 3.2rem;
  font-weight: 300;
  letter-spacing: -0.1rem;
  position: relative;
}
.ProfileTap_welcome-center-btn p:hover {
  color: #292929;
  cursor: pointer;
}

.ProfileTap_welcome-center-btn img {
  position: absolute;
  top: -3px;
  left: 80px;
}

.ProfileTap_welcome-center-username {
  font-size: 3.4rem;
  font-weight: 500;
  letter-spacing: -0.1rem;
  margin-right: 2.4px;
}

.ProfileTap_welcome-center-btn p {
  margin-top: 0.8em;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  color: gray;
}

.ProfileTap_competitionCount-box {
  background-color: #e7f3ff;
  margin-top: auto;
  margin-bottom: auto;
  height: 80%;
  width: 48%;
  box-sizing: border-box;
  position: relative;

  border-radius: 10px;
}
.nowCnt {
  margin-right: auto;
}
.totalCnt {
  background-color: #eeeaff;
}

.ProfileTap_competitionCount-box-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
  font-size: 1.4rem;
  width: 100%;
}

.ProfileTap_competitionCount-box-num {
  font-size: 4.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.ProfileTap_information-btn {
  background: white;
  border-bottom: 1px solid #eeeeee;
  font-size: 1.8rem;
  height: 60px;
  line-height: 60px;
  padding-left: 16px;
  box-sizing: border-box;
  cursor: pointer;
}

.ProfileTap_information-btn:hover {
  background: #f5f5f5;
}

@media (max-width: 1049px) {
  /* Layout */

  #ProfileTap_dissaper {
    display: none !important;
    background-color: red;
  }

  #ProfileTap_live {
    display: block;
    width: 100%;
  }
}

@media (max-width: 750px) {
  #ProfileTap_live {
    height: 88vh;
  }
}
