.Error_wrapper {
  height: 84vh;
  max-width : 1200px;
  box-sizing: border-box;
  padding: 0 2rem;
  margin: 0 auto;
  letter-spacing: -0.1em;
}

.Error_card {
  position: relative;
  top: 2%;
}

/* 나주하 */
.Error_card h1 {
  font-size: 5.6rem;
  font-family: 'Anton', sans-serif;
  margin-bottom: 4px;
}

/* 에러 번호 */
.Error_num {
  font-size: 2rem;
  font-weight: 500;
  width: 160px;
  height: 32px;
  background: #e9f3fd;
  border-radius: 15px;
  text-align: center;
  line-height: 32px;

  margin-bottom: 24px;
}

/* 에러 메시지 */
.Error_card h2 {
  font-size: 2.4rem;
  font-weight: 500;

  margin-bottom: 10px;
}

/* 에러 상세 메시지 */
.Error_card h3 {
  font-size: 1.8rem;
  color: #606060;
  line-height: 24px;

  margin-bottom: 24px;
}

/* 구분선 */
.Error_wrapper hr {
  margin-bottom: 24px;
  background-color: #e0e0e0; 
  height: 1px;
  border: 0px;
}

/* 에러 해결 메시지 */
.Error_solution {
  margin-bottom: 20px;
}

.Error_solution h3 {
  font-size: 1.8rem;
  color: #4a4a4a;
  line-height: 20px;
  margin-bottom: 12px;
}

.Error_solution li {
  font-size: 2rem;
  color: #000000;
  line-height: 24px;
  margin-bottom: 6px;
}

.Error_solution span {
  font-weight: 500;
  color: #4e82e6;
}

/* 나주하채널 안내 메시지 */
.Error_final {

}

.Error_final h3{
  font-size: 1.8rem;
  color: #4a4a4a;
  line-height: 20px;
  margin-bottom: 12px;
}

.Error_final h4{
  font-size: 2rem;
  color: #000000;
  line-height: 20px;

}

.Error_final span{
  font-weight: 500;
  /* color: #0f25a3; */
  text-decoration: underline;
  cursor: pointer;
  color: #4e82e6;
}

/* 버튼 */
.Error_card button {
  background: #4e82e5;
  border: 2px solid #4e82e6;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: #ffffff;
  cursor: pointer;

  padding: auto;
  width: 130px;
  min-width: 130px;
  height: 46px;
  min-height: 46px;
  font-size: 1.8rem;
}

@media (max-width: 1049px) {
  .Error_wrapper {
    max-width : 768px;
  }
}

@media (max-width: 750px) {

  .Error_wrapper {
    height: 88vh;
    max-width : 650px;
  }

  /* 나주하 */
  .Error_card h1 {
    font-size: 4.2rem;
    margin-bottom: 4px;
  }

  /* 에러 번호 */
  .Error_card p {
    font-size: 1.8rem;
    width: 120px;
    height: 28px;
    line-height: 28px;

    margin-bottom: 24px;
  }

  /* 에러 메시지 */
  .Error_card h2 {
    font-size: 2.2rem;
    margin-bottom: 8px;
  }

  /* 에러 상세 메시지 */
  .Error_card h3 {
    font-size: 1.6rem;
    color: #707070;
    line-height: 18px;
    margin-bottom: 12px;
  }

  /* 구분선 */
  .Error_wrapper hr {
    margin-bottom: 16px;
  }

  /* 에러 해결 메시지 */
  .Error_solution h3 {
    font-size: 1.6rem;
    margin-bottom: 6px;
  }

  .Error_solution li {
    font-size: 1.8rem;
    line-height: 22px;
    margin-bottom: 4px;
  }

  /* 나주하채널 안내 메시지 */
  .Error_final h3{
    font-size: 1.6rem;
    margin-bottom: 6px;
    line-height: 20px;
  }

  .Error_final h4{
    font-size: 1.8rem;
    line-height: 20px;
  }

  .Error_final span{
    font-weight: 500;
    /* color: #0f25a3; */
    text-decoration: underline;
    cursor: pointer;
  }
}
