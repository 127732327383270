/* modal창 외부화면 */
.Paymentbridgemodal_Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* modal창 */
.Paymentbridgemodal_modalBody {
  position: absolute;
  width: 270px;
  height: auto;
  padding: 20px;
  text-align: left;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}

.Paymentbridgemodal_buttongroup {
  margin-top: 30px;
  width: 100%;
}

.Paymentbridgemodal_button_later,
.Paymentbridgemodal_button_now {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 48%;
  height: 46px;
  font-size: 18px;
  cursor: pointer;
}

.Paymentbridgemodal_button_later {
  color: #8c8c8c;
  margin-right: 4%;
}

.Paymentbridgemodal_button_now {
  color: #4e82e5;
}

#Paymentbridgemodal_modaltitle {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 14px;
}

.Paymentbridgemodal_modalBody h3 {
  font-size: 14px;
  color: #7c7c7c;
}
