/* modal창 외부화면 */
.ApplyModal_Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* modal창 */
.ApplyModal_modalBody {
  position: absolute;
  width: 270px;
  height: auto;
  padding: 20px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}

.ApplyModal_title {
  display: flex;
  justify-content: space-between;
}

.ApplyModal_modalform {
  margin-top: 20px;
}

.ApplyModal_modalinputdiv {
  text-align: left;
  margin-bottom: 10px;
}

.ApplyModal_modalinputdiv label {
  /* position: absolute; */
  /* margin-right: 34px; */
  display: inline-block;
  width: 70px;
  font-size: 14px;
  color: #8c8c8c;
}

.ApplyModal_modalinputdiv input {
  width: 180px;
  height: 35px;
  padding-left: 10px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.ApplyModal_modalform button {
  margin-top: 10px;
  width: 100%;
  margin-top: 10px;
  height: 46px;
  min-height: 46px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-weight: 400;
  font-size: 18px;
  color: #4e82e5;
  cursor: pointer;
}

#ApplyModal_modaltitle {
  font-weight: 600;
  font-size: 20px;
}

#ApplyModal_modalCloseBtn {
  border: none;
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  font-size: 20px;
}

#ApplyModal_modalCloseBtn:hover {
  cursor: pointer;
}
