.AdminCsvDownload body {
  padding: 1.5em;
  background: #f5f5f5;
}

.AdminCsvDownload table {
  border: 1px #a39485 solid;
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

.AdminCsvDownload th {
  text-align: left;
}

.AdminCsvDownload thead {
  font-weight: bold;
  color: #fff;
  background: #73685d;
}

.AdminCsvDownload td,
th {
  padding: 1em 0.5em;
  vertical-align: middle;
}

.AdminCsvDownload td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.AdminCsvDownload a {
  color: #73685d;
}

.AdminCsvDownload @media all and (max-width: 768px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  .AdminCsvDownload th {
    text-align: right;
  }

  .AdminCsvDownload table {
    position: relative;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .AdminCsvDownload thead {
    float: left;
    white-space: nowrap;
  }

  .AdminCsvDownload tbody {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
  }

  .AdminCsvDownload tr {
    display: inline-block;
    vertical-align: top;
  }

  .AdminCsvDownload th {
    border-bottom: 1px solid #a39485;
  }

  .AdminCsvDownload td {
    border-bottom: 1px solid #e5e5e5;
  }
}
