/* AdminUserList.css */

.AdminUserList-wrapper {
  width: 80%;
  margin: 0 auto;
}

.AdminUserList {
  width: 100%;
}

.AdminUserList table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.AdminUserList table th,
.AdminUserList table td {
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: left;
}

.AdminUserList table th {
  background-color: #f5f5f5;
}

.AdminUserList table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.AdminUserList table tbody tr:hover {
  background-color: #f0f0f0;
}
