/* Typography */

.CompetitionApplyTeamForm-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.CompetitionApplyTeamForm-teaminfo label {
  font-size: 1.4rem;
}

.CompetitionApplyTeamForm-top-table-column li {
  font-size: 1.4rem;
  font-weight: 600;
}

.CompetitionApplyTeamForm-top-table-row li {
  font-size: 1.6rem;
  color: #8c8c8c;
}

.CompetitionApplyTeamForm-top-table-row li p {
  width: 86%;
}

.CompetitionApplyTeamForm-top-table-row input {
  font-size: 1.6rem;
}

.CompetitionApplyTeamForm-bottom-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

.CompetitionApplyTeamForm-bottom-table-column li {
  font-size: 1.4rem;
  font-weight: 500;
  color: #8c8c8c;
}

.CompetitionApplyTeamForm-bottom-table-column li:first-child {
  color: #8c8c8c;
}

.CompetitionApplyTeamForm-bottom-table-row li {
  font-size: 1.6rem;
  font-weight: 400;
}

.CompetitionApplyTeamForm-bottom-table-row li:first-child {
  color: #8c8c8c;
}

.CompetitionApplyTeamForm-bottom-table-result {
  font-size: 1.6rem;
  font-weight: 700;
}

.CompetitionApplyTeamForm-bottom-table-result-red {
  color: #ff5f5f;
}

#CompetitionApplyTeamForm-bottom-table-result-key {
  font-size: 1.6rem;
  font-weight: 400;
}

#CompetitionApplyTeamForm-bottom-table-buttons-save {
  color: #8c8c8c;
}

#CompetitionApplyTeamForm-bottom-table-buttons-register {
  color: #4e82e5;
}

/* Layout */

.CompetitionApplyTeamForm-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: 84vh;
  box-sizing: border-box;
}

.CompetitionApplyTeamForm-top {
  position: relative;
  margin-bottom: 40px;
}

.CompetitionApplyTeamForm-title {
  text-align: center;
  padding: 4vh 0;
}

.CompetitionApplyTeamForm-teaminfo {
  text-align: center;
  margin-bottom: 30px;
  /* display: inline-block; */
}

.CompetitionApplyTeamForm-teaminfo label {
  margin-left: 25px;
}

.CompetitionApplyTeamForm-teaminfo input {
  margin-left: 10px;
}

.CompetitionApplyTeamForm-teaminfo input::placeholder {
  font-size: 1.4rem;
}
.CompetitionApplyTeamForm-top-table-row li input::placeholder {
  font-size: 1.4rem;
}

.CompetitionApplyTeamForm-top-table {
  width: 82%;
  margin: 0 auto;
}

.CompetitionApplyTeamForm-top-table-child1 {
  width: 57.142%;
  display: inline-block;
}

.CompetitionApplyTeamForm-top-table-child2 {
  width: 42.858%;
  display: inline-block;
}

.CompetitionApplyTeamForm-top-table-column {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionApplyTeamForm-top-table-column li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
}

.CompetitionApplyTeamForm-top-table-row {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionApplyTeamForm-top-table-row li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
  font-weight: 400;
}

/* 여기서부터바텀 Layout */

.CompetitionApplyTeamForm-bottom-table {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 44px;
}

.CompetitionApplyTeamForm-bottom-table-buttons button {
  margin-left: 35px;
  margin-right: 35px;
  font-size: 1.8rem;
}

/*Component*/

.CompetitionApplyTeamForm-teaminfo-element {
  display: inline-block;
}

.CompetitionApplyTeamForm-teaminfo input {
  width: 170px;
  height: 30px;
  border: 1px solid #e2eaef;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.6rem;
}

.CompetitionApplyTeamForm-top-table-column li {
  background: #e9f3fd;
  border: 1px solid #e2eaef;
}

.CompetitionApplyTeamForm-top-table-child1 li:first-child {
  border-radius: 5px 0px 0px 0px;
}

.CompetitionApplyTeamForm-top-table-child2 li:last-child {
  border-radius: 0px 5px 0px 0px;
}

.CompetitionApplyTeamForm-top-table-row li {
  border: 1px solid #e2eaef;
  position: relative;
  cursor: pointer;
}

.CompetitionApplyTeamForm-top-table-row li:first-child {
  border-radius: 0px 0px 0px 5px;
  padding-left: 10px;
}

.CompetitionApplyTeamForm-top-table-row li input {
  margin-left: -7px;
  padding-left: 7px;
}

.CompetitionApplyTeamForm-top-table-row li:last-child {
  border-radius: 0px 0px 5px 0px;
}

#CompetitionApplyTeamForm-top-table-ginogi {
  border-radius: 0px;
}

#CompetitionApplyTeamForm-top-table-division {
  border-radius: 0px;
}

.CompetitionApplyTeamForm-top-table-row-dropdown-icon {
  position: absolute;
  right: 5px;
  bottom: 12px;
}

.CompetitionApplyTeamForm-top-table-row input {
  box-sizing: border-box;
  border: 0;
  border-radius: 0px 0px 0px 5px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

#CompetitionApplyTeamForm-top-table-row-dropdown {
  position: absolute;
  top: 39px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

#CompetitionApplyTeamForm-top-table-row-dropdown li {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e2eaef;
  border-top: none;
  border-radius: 0px;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #000000;
}

.CompetitionApplyTeamForm-top-table-row-disable {
  pointer-events: none;
  opacity: 0.7;
  background-color: #cecece;
}

.CompetitionApplyTeamForm-button-add {
  position: absolute;
  background: #4e82e5;
  color: #ffffff;
  border: 1px solid #f1f1f1;
  /* box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25); */
  border-radius: 30px;
  height: 40px;
  width: 90px;
  min-height: 40px;
  min-width: 90px;
  font-size: 1.6rem;

  right: -20px;
  bottom: 0px;
  cursor: pointer;
}

/* .CompetitionApplyTeamForm-teaminfo input::placeholder{
    padding-left: 5px;
} */

/* 바텀테이블 component */

.CompetitionApplyTeamForm-bottom-table {
  width: 99%;
  border: 1px solid #e2eaef;
  border-radius: 5px;
}

.CompetitionApplyTeamForm-bottom-table-column {
  width: 100%;
  display: table;
  margin: 0 auto;
}

.CompetitionApplyTeamForm-bottom-table-column li {
  width: 11.9%;
  display: table-cell;
  height: 35px;
  padding-left: 5px;
  vertical-align: middle;
  background: #f4f4f4;
  border: none;
}

.CompetitionApplyTeamForm-bottom-table-column li:first-child {
  padding-left: 0;
  text-align: center;
  width: 4.8%;
  border-radius: 5px 0px 0px 0px;
}

.CompetitionApplyTeamForm-bottom-table-column li:last-child {
  border-radius: 0px 5px 0px 0px;
}

.CompetitionApplyTeamForm-bottom-table-row {
  width: 100%;
  display: table;
  margin: 0 auto;
}

.CompetitionApplyTeamForm-bottom-table-row.canceled {
  background-color: gainsboro;
}

.CompetitionApplyTeamForm-bottom-table-row.disabled {
  background: #ffffff;
  color: #8c8c8c;
}

.CompetitionApplyTeamForm-bottom-table-row li {
  width: 11.9%;
  display: table-cell;
  height: 44px;
  padding: 0 6px;
  vertical-align: middle;
  border-bottom: 1px solid #e2eaef;
  box-sizing: border-box;
}

.CompetitionApplyTeamForm-bottom-table-row li:first-child {
  padding-left: 0px;
  text-align: center;
  width: 4.8%;
}

.CompetitionApplyTeamForm-bottom-table-results {
  width: 240px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.CompetitionApplyTeamForm-bottom-table-result {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.CompetitionApplyTeamForm-bottom-table-buttons {
  text-align: center;
}

.CompetitionApplyTeamForm-bottom-table-buttons button {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  /* 버튼그림자 */
  width: 110px;
  height: 46px;
  min-width: 110px;
  min-height: 46px;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
}

#CompetitionApplyTeamForm-bottom-table-row-deleteicon {
  position: absolute;
  transform: translate(50%, 50%);
  cursor: pointer;
}

@media (max-width: 1049px) {
  .CompetitionApplyTeamForm-wrapper {
    width: 700px;
  }

  .CompetitionApplyTeamForm-top-table {
    width: 100%;
    margin: 0 auto;
  }

  .CompetitionApplyTeamForm-button-add {
    right: 0px;
    bottom: -40px;
    height: 36px;
    width: 82px;
    min-height: 36px;
    min-width: 82px;
    font-size: 1.4rem;
  }

  .CompetitionApplyTeamForm-bottom-table {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .CompetitionApplyTeamForm-wrapper {
    width: 90%;
  }

  .CompetitionApplyTeamForm-teaminfo-element {
    display: block;
    margin-bottom: 5px;
  }

  .CompetitionApplyTeamForm-teaminfo-element label {
    display: inline-block;
    width: 80px;
    min-width: 80px;
  }

  .CompetitionApplyTeamForm-top-table-child1 {
    width: 100%;
  }

  .CompetitionApplyTeamForm-top-table-child2 {
    width: 100%;
  }

  .CompetitionApplyTeamForm-top-table-child1 li:last-child {
    border-radius: 0px 5px 0px 0px;
  }

  .CompetitionApplyTeamForm-top-table-child2 li:first-child {
    border-radius: 5px 0px 0px 0px;
  }

  #CompetitionApplyTeamForm-top-table-ginogi {
    border-radius: 0 0 5px 0px;
  }

  #CompetitionApplyTeamForm-top-table-division {
    border-radius: 0 0 0 5px;
  }

  /* 바텀 */
  #CompetitionApplyTeamForm-bottom-table-row-deleteicon {
    transform: none;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  .CompetitionApplyTeamForm-bottom-table {
    border: none;
  }

  .CompetitionApplyTeamForm-bottom-table-column {
    display: none;
  }

  .CompetitionApplyTeamForm-bottom-table-row {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    border-left: 2px solid #e2eaef;
    border-top: 2px solid #e2eaef;
    border-radius: 5px;
  }

  .CompetitionApplyTeamForm-bottom-table-row li {
    width: 33.33%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e2eaef;
    border-right: 2px solid #e2eaef;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:first-child {
    width: 4.8%;
    min-width: 10px;
    padding-left: 10px;
    justify-content: center;
    border-right: none;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(2) {
    width: 23.8%;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(3) {
    width: 23.8%;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(4) {
    width: 23.8%;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(5) {
    width: 23.8%;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(5) {
    border-radius: 0 5px 0 0;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(6) {
    border-radius: 0 0 0 5px;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(8) {
    border-radius: 0 0 5px 0;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:nth-child(9) {
    display: none;
  }

  .CompetitionApplyTeamForm-bottom-table-row li:first-child {
    font-weight: 700;
    color: #000000;
  }

  .CompetitionApplyTeamForm-bottom-table-result {
    justify-content: space-between;
    font-size: 1.8rem;
  }

  #CompetitionApplyTeamForm-bottom-table-result-key {
    color: #8c8c8c;
    font-size: 1.4rem;
  }

  .CompetitionApplyTeamForm-bottom-table-buttons button {
    margin-left: 20px;
    margin-right: 20px;

    width: 96px;
    height: 40px;
    min-width: 96px;
    min-height: 40px;
    font-size: 1.6rem;
  }
}
