@import url('https://fonts.googleapis.com/css2?family=Anton&family=Noto+Sans+KR&display=swap');

/* Typography */
:root {
  --gray-nav-color: #888888;

  --black-title-color: #000000;
  --gray-subtitle-color: #1f1f1f;
  --gray-info-color: #a0a0a0;
  --blue-background-color: #f2f7ff;
  --blue-impact-color: #90ccf8;
  --white-color: white;
  --gray-buttonStroke-color: #acacac;
  --gray-belt-color: #d7d7d7;

  font-size: 62.5%; /* 10px */

  --font-size-navlogo: 4rem;
  --font-size-navlist: 2rem;
  --font-size-title1: 5rem;
  --font-size-subtitle1: 2.4rem;
  --font-size-subinfo: 1.8rem;
  --font-size-title2: 3.6rem;
  --font-size-subtitle2: 1.8rem;
}

/* Layout */
.MainScroll_wrapper {
  justify-content: center;
  scroll-behavior: smooth;
  overflow: hidden;
}

.MainScroll_section1 {
  position: fixed;
}
.MainScroll_phoneMsg {
  color: var(--blue-background-color);
}
/* Components */

/* section 1 */

.MainScroll_section1 {
  height: 100vh;
  width: 100vw;
  background-color: white;
  box-sizing: border-box;
  z-index: 88;
}

#MainScroll_bgImg {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  object-fit: cover;
  position: fixed;
  z-index: 10;
}

.MainScroll_black {
  position: absolute;
  height: 100vh;
  z-index: 20;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
}
.MainScroll_black2 {
  position: fixed;
  height: 120vh;
  z-index: 20;
  width: 100vw;
}

/* 나는 주짓수가 하고싶다 */
.MainScroll_message {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  text-align: center;
}

.MainScroll_message h1 {
  font-family: 'Anton', sans-serif;
  color: var(--white-color);
  font-size: 18rem;
}
#MainScroll_span {
  color: white;
  font-size: 3.8rem;
  line-height: 4.6rem;
  font-weight: 300;
  letter-spacing: 3%;
}

.MainScroll_message h2 span {
  font-size: 3.8rem;
  line-height: 4.6rem;
  font-weight: 600;
  letter-spacing: 3%;
}

.MainScroll_message2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  text-align: center;
  width: 100%;
}

.MainScroll_message2 h2 {
  color: var(--white-color);
  font-weight: 500;
  font-size: 4.6rem;
  line-height: 6.2rem;
  margin-bottom: 40px;
}

.MainScroll_message2 h2:last-child {
  margin-bottom: 0px;
}

.MainScroll_none {
  opacity: 0;
}

.MainScroll_fadein {
  opacity: 1;
  transition: 0.5s;
}

.MainScroll_fadeout {
  opacity: 0;
  transition: 0.5s;
}

.MainScroll_message span {
  font-weight: 900;
  font-size: 10rem;
  line-height: 11rem;
}

.MainScroll_section1-1 {
  height: 4700px;
  position: relative;
  background-color: white;
  z-index: 1;
}

/* 스크롤 이미지 */
@keyframes motion {
  0% {
    margin-top: 0px;
  } /* 처음 위치 */
  100% {
    margin-top: 10px;
  } /* 마지막 위치 */
}

.MainScroll_scrollImg {
  color: white;
  z-index: 999;
  position: fixed;
  top: 88%;
  left: 50%;
  transform: translateX(-50%);
  animation: motion 0.6s /* 속도 */ linear 0s
    /* 처음부터 끝까지 일정 속도로 진행 */ infinite alternate; /* 무한 반복 */
}
.MainScroll_scrollImg p {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.MainScroll_scrollImg img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* section 2 */
.MainScroll_section2 {
  z-index: 100;
  position: relative;
  align-items: center;
  text-align: center;
  height: 100%;
  /* background-color: white; */
  padding-top: 10vh;
}

.MainScroll_title1 {
  margin-top: 18rem;
}

.MainScroll_section2 h1 {
  position: relative;
  font-size: var(--font-size-title1);
  line-height: 7rem;
  font-weight: 700;
  z-index: 2;
}

.MainScroll_section2 h2 {
  font-size: var(--font-size-subtitle1);
  color: var(--gray-subtitle-color);
  line-height: 2.8rem;
  font-weight: 400;
  margin-top: 8.2rem;
  margin-bottom: 4rem;
}

.MainScroll_blueLine {
  background: #90ccf8;
  width: 16rem;
  height: 2rem;
  border-radius: 25px;
  position: absolute;
  top: 19.8rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.MainScroll_section2 img {
  width: 60%;
}

/* 중간 그라데이션 */
.MainScroll_linear1 {
  position: relative;
  z-index: 888;
  height: 18.4rem;
  background: linear-gradient(180deg, #ffffff 0%, #f2f7ff 100%);
}

/* section 3 */

.MainScroll_section3 {
  position: relative;
  z-index: 888;
  background-color: var(--blue-background-color);
  height: 110%;
  padding: 140px 0;
}

.MainScroll_section3 h1 {
  position: relative;
  font-size: var(--font-size-title1);
  line-height: 7rem;
  font-weight: 700;
  z-index: 2;
  /* max-width: 821px; */
}

.MainScroll_title2 {
  max-width: 82.1rem;
  margin: 0 auto;
  padding-top: 6rem;
}

.MainScroll_blueLine2 {
  background: #90ccf8;
  width: 16rem;
  height: 2rem;
  border-radius: 25px;
  position: absolute;
  top: 25.8rem;
  left: 8rem;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.MainScroll_phone {
  display: flex;
  justify-content: center;
}

.MainScroll_phone img {
  height: 80rem;
}

.MainScroll_phone1 {
  padding-top: 8.6rem;
}

.MainScroll_phone2 {
  margin-left: 0rem;
  margin-top: -10rem;
}

.MainScroll_phone1 p {
  font-size: var(--font-size-subinfo);
  color: var(--gray-info-color);
  margin-left: 2rem;
}

.MainScroll_phone2 h2 {
  font-size: var(--font-size-subtitle1);
  color: var(--gray-subtitle-color);
  line-height: 3.6rem;
  margin-left: 2rem;
  max-width: 280px;
}

/* 중간 그라데이션 */
.MainScroll_linear2 {
  position: relative;
  z-index: 888;
  height: 18.4rem;
  background: linear-gradient(180deg, #f2f7ff 0%, #ffffff 100%);
}

/* section 4 */

.MainScroll_section4 {
  position: relative;
  z-index: 888;
  background-color: white;
  /* height: 90vh; */
  /* align-items: center; */
  text-align: center;
}

.MainScroll_section4 h1 {
  position: relative;
  font-size: 46px;
  line-height: 4.6rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  z-index: 2;
  margin-top: 18rem;
}

.MainScroll_blueLine3 {
  background: #90ccf8;
  width: 21.2rem;
  height: 1rem;
  border-radius: 25px;
  position: absolute;
  top: 12.4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.MainScroll_title3 h2 {
  font-size: var(--font-size-subtitle2);
  color: var(--gray-subtitle-color);
  margin-top: 2.4rem;
}

/* 카드 슬라이드 디자인 */
.MainScroll_slide {
  margin-top: 67px;
}

.MainScroll_card {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
  max-width: 580px;
  border: 1px solid #dbdbdb;
  /* box-shadow: 0 0 0 1px #dbdbdb inset; */
  cursor: pointer;
}
.MainScroll_card:hover {
  border: 1px solid #131313;
}

.MainScroll_cardInfo {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  width: 100%;
  text-align: left;
  font-weight: 400;
  letter-spacing: -0.04em;
  margin-left: 10px;
}

.MainScroll_cardInfo p {
  font-size: 24px;
  margin-bottom: 5%;
  margin-top: 10%;
  height: 15%;
}

.MainScroll_cardInfo h2 {
  font-size: 30px;
  font-weight: 500;
  width: 96%;
  height: 35%;
  margin-bottom: 15%;
}

.MainScroll_cardInfo h3 {
  font-size: 18px;
  height: 20%;
  margin-bottom: 10%;
  color: #696969;
  width: 96%;
}
/* 
.MainScroll_cardInfo button {
  margin-right: 20px;
  width: 120px;
  height: 46px;
  background: rgba(255, 255, 255, 0.26);

  border: 1px solid #acacac;
  box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  font-size: 18px;
} */

.MainScroll_apply {
  margin-left: auto;
}

#card {
  position: static;
  text-align: center;
  justify-content: center;
}

.MainScroll_card img {
  width: 40%;
  height: 296px;
  min-width: 230px;
}

.arrow {
  position: absolute;
  z-index: 999;
}

/* 모든 대회 보러가기 버튼 */

.MainScroll_listBtn {
  margin-top: 60px;

  display: inline-block;
  font-size: 18px;
  color: #767676;
  letter-spacing: -0.04em;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1049px) {
  /* 섹션 1 */

  /* 섹션 2 */
  .MainScroll_section2 img {
    width: 90%;
  }
}

@media (max-width: 850px) {
  /* 섹션 1 */
  .MainScroll_message {
    top: 40%;
  }
  .MainScroll_message h1 {
    font-size: 11rem;
  }
  #MainScroll_span {
    font-size: 2.4rem;
  }
  .MainScroll_message h2 span {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .MainScroll_message2 {
    top: 46%;
    max-width: 280px;
    margin-top: -20px;
  }

  .MainScroll_message2 h2 {
    font-size: 3.4rem;
    line-height: 4rem;
    margin-bottom: 40px;
  }

  /* 섹션 1-1 */
  .MainScroll_section1-1 {
    height: 4600px;
  }
  /* 섹션 2 (모니터 사진)*/

  .MainScroll_section2 h1 {
    font-size: 4rem;
    line-height: 4.6rem;
  }

  .MainScroll_section2 h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-top: 7rem;
    margin-bottom: 2.2rem;
  }

  .MainScroll_blueLine {
    width: 13rem;
    height: 1.4rem;
    top: 13.2rem;
  }

  .MainScroll_subtitle1 {
    max-width: 220px;
    margin: 0 auto;
  }

  /* 섹션 3 (핸드폰 사진)*/
  .MainScroll_title2 {
    margin-left: 2.6rem;
  }

  .MainScroll_section3 {
    padding: 50px 0;
  }

  .MainScroll_section3 h1 {
    font-size: 4rem;
    line-height: 4.6rem;
  }

  .MainScroll_blueLine2 {
    width: 13rem;
    height: 1.4rem;
    top: 19.2rem;
    left: 6.4rem;
  }

  .MainScroll_phone {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 730px;
  }

  .MainScroll_phone1 {
    width: 50%;
  }

  .MainScroll_phone2 {
    width: 50%;
    margin-top: 8.6rem;
    margin-left: 0rem;
  }

  .MainScroll_phone img {
    width: 100%;
    height: 80%;
  }

  .MainScroll_phone1 p {
    font-size: 14px;
    color: var(--gray-info-color);
    margin-left: 2rem;

    width: 580px;
  }

  .MainScroll_phone2 h2 {
    display: none;
  }
  .MainScroll_phoneMsg {
    z-index: 99;
    margin-left: 2rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: var(--gray-subtitle-color);
    margin-top: 1rem;
    width: 400px;
    margin-bottom: 1rem;
  }

  .MainScroll_title3 h2 {
    font-size: 1.6rem;
    margin-top: 1.4rem;
  }
  /* section 4 - 카드 슬라이드 */
  .MainScroll_card img {
    width: 40%;
    height: 230px;
    min-width: 180px;
  }

  .MainScroll_cardInfo {
    margin-left: 6px;
  }

  .MainScroll_cardInfo p {
    font-size: 16px;
  }

  .MainScroll_cardInfo h2 {
    font-size: 20px;
  }

  .MainScroll_cardInfo h3 {
    font-size: 14px;
  }

  .MainScroll_listBtn {
    font-size: 16px;
  }

  .MainScroll_section4 h1 {
    font-size: 34px;
    margin-top: 12rem;
  }
}

@media (max-width: 480px) {
  /* section 4 - 카드 슬라이드 */
  .MainScroll_card img {
    width: 40%;
    height: 180px;
    min-width: 120px;
  }

  .MainScroll_section4 {
    /* height: 82vh; */
  }
}
