/* 대회 신청 목록 필터 */
.CompetitionApplicationList-filter-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.CompetitionApplicationList-filter-teaminfo label {
  font-size: 1.4rem;
}

.CompetitionApplicationList-filter-top-table-column li {
  font-size: 1.4rem;
  font-weight: 600;
}

.CompetitionApplicationList-filter-top-table-row li {
  font-size: 1.6rem;
  color: #8c8c8c;
}

.CompetitionApplicationList-filter-top-table-row li p {
  width: 86%;
}

.CompetitionApplicationList-filter-top-table-row input {
  font-size: 1.6rem;
}

.CompetitionApplicationList-filter-bottom-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

/* Layout */

.CompetitionApplicationList-filter-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: 84vh;
  box-sizing: border-box;
}

.CompetitionApplicationList-filter-top {
  position: relative;
  margin-bottom: 40px;
}

.CompetitionApplicationList-filter-title {
  text-align: center;
  padding: 4vh 0;
}

.CompetitionApplicationList-filter-teaminfo {
  text-align: center;
  margin-bottom: 30px;
  /* display: inline-block; */
}

.CompetitionApplicationList-filter-teaminfo label {
  margin-left: 25px;
}

.CompetitionApplicationList-filter-teaminfo input {
  margin-left: 10px;
}

.CompetitionApplicationList-filter-teaminfo input::placeholder {
  font-size: 1.4rem;
}
.CompetitionApplicationList-filter-top-table-row li input::placeholder {
  font-size: 1.4rem;
}

.CompetitionApplicationList-filter-top-table {
  width: 82%;
  margin: 0 auto;
}

.CompetitionApplicationList-filter-top-table-child1 {
  width: 40%;
  display: inline-block;
}

.CompetitionApplicationList-filter-top-table-child2 {
  width: 60%;
  display: inline-block;
}

.CompetitionApplicationList-filter-top-table-column {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionApplicationList-filter-top-table-column li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
}

.CompetitionApplicationList-filter-top-table-row {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionApplicationList-filter-top-table-row li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
  font-weight: 400;
}

/*Component*/

.CompetitionApplicationList-filter-teaminfo input {
  width: 170px;
  height: 30px;
  border: 1px solid #e2eaef;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.6rem;
}

.CompetitionApplicationList-filter-top-table-column li {
  background: #e9f3fd;
  border: 1px solid #e2eaef;
}

.CompetitionApplicationList-filter-top-table-child1 li:first-child {
  border-radius: 5px 0px 0px 0px;
}

.CompetitionApplicationList-filter-top-table-child2 li:last-child {
  border-radius: 0px 5px 0px 0px;
}

.CompetitionApplicationList-filter-top-table-row li {
  border: 1px solid #e2eaef;
  position: relative;
  cursor: pointer;
}

.CompetitionApplicationList-filter-top-table-row li:first-child {
  border-radius: 0px 0px 0px 5px;
  padding-left: 10px;
}

.CompetitionApplicationList-filter-top-table-row li input {
  margin-left: -7px;
  padding-left: 7px;
}

.CompetitionApplicationList-filter-top-table-row li:last-child {
  border-radius: 0px 0px 5px 0px;
}

#CompetitionApplicationList-filter-top-table-ginogi {
  border-radius: 0px;
}

#CompetitionApplicationList-filter-top-table-division {
  border-radius: 0px;
}

.CompetitionApplicationList-filter-top-table-row-dropdown-icon {
  position: absolute;
  right: 5px;
  bottom: 12px;
}

.CompetitionApplicationList-filter-top-table-row input {
  box-sizing: border-box;
  border: 0;
  border-radius: 0px 0px 0px 5px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

#CompetitionApplicationList-filter-top-table-row-dropdown {
  position: absolute;
  top: 39px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

#CompetitionApplicationList-filter-top-table-row-dropdown li {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e2eaef;
  border-top: none;
  border-radius: 0px;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #000000;
}

.CompetitionApplicationList-filter-top-table-row-disable {
  pointer-events: none;
  opacity: 0.7;
  background-color: #cecece;
}

/* 바텀테이블 component */

@media (max-width: 1049px) {
  .CompetitionApplicationList-filter-wrapper {
    width: 700px;
  }

  .CompetitionApplicationList-filter-top-table {
    width: 100%;
    margin: 0 auto;
  }

  .CompetitionApplicationList-filter-button-add {
    right: 0px;
    bottom: -40px;
    height: 36px;
    width: 82px;
    min-height: 36px;
    min-width: 82px;
    font-size: 1.4rem;
  }
}

@media (max-width: 750px) {
  .CompetitionApplicationList-filter-wrapper {
    width: 90%;
  }

  .CompetitionApplicationList-filter-top-table-child1 {
    width: 100%;
  }

  .CompetitionApplicationList-filter-top-table-child2 {
    width: 100%;
  }

  .CompetitionApplicationList-filter-top-table-child1 li:last-child {
    border-radius: 0px 5px 0px 0px;
  }

  .CompetitionApplicationList-filter-top-table-child2 li:first-child {
    border-radius: 5px 0px 0px 0px;
  }

  #CompetitionApplicationList-filter-top-table-ginogi {
    border-radius: 0 0 5px 0px;
  }

  #CompetitionApplicationList-filter-top-table-division {
    border-radius: 0 0 0 5px;
  }
}

/* Csv Table */
.CompetitionApplicantList-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto; /* 스크롤 적용 */
}

.CompetitionApplicantList-csv-wrapper {
  overflow: auto;
  max-height: 80vh;
  padding: 10px;
}

.CompetitionApplicantList-csv {
  padding: 10px;
}

.CompetitionApplicantList-csv body {
  padding: 1.5em;
  background: #f5f5f5;
}

.CompetitionApplicantList-csv table {
  border: 1px #a39485 solid;
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

.CompetitionApplicantList-csv th {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5em 1em;
  font-size: 1.4rem;
}

.CompetitionApplicantList-csv thead {
  font-weight: bold;
  color: #000000;
  background: #f5f5f5;
}

.CompetitionApplicantList-csv td,
th {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5em 1em;
  vertical-align: middle;
  font-size: 1.6rem;
  min-width: 90px;
  text-align: center;
}

.CompetitionApplicantList-csv td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5em 1em;
  vertical-align: middle;
}

.CompetitionApplicantList-csv a {
  color: #73685d;
}

.CompetitionApplicantList-csv @media all and (max-width: 750px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  .CompetitionApplicantList-csv th {
    text-align: center;
  }

  .CompetitionApplicantList-csv table {
    table-layout: fixed;
    position: relative;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .CompetitionApplicantList-csv thead {
    float: left;
    white-space: nowrap;
  }

  .CompetitionApplicantList-csv tbody {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
  }

  .CompetitionApplicantList-csv tr {
    display: inline-block;
    vertical-align: top;
  }

  .CompetitionApplicantList-csv th {
    border-bottom: 1px solid #a39485;
  }

  .CompetitionApplicantList-csv td {
    border-bottom: 1px solid #e5e5e5;
  }
}
