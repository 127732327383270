.wmde-markdown {
  line-height: 1;
  letter-spacing: -0.04em;
  white-space: normal !important;
}

/* 수평선 */
.wmde-markdown hr {
  border-bottom: 1px solid #eeeeee;
  height: 0;
  margin: 0;
  margin-top: 50px;
}

/* 세부항목 타이틀 */
.wmde-markdown h3 {
  margin-bottom: 20px;
  margin-top: 12px;
  color: #8c8c8c;
  font-size: 22px;
  font-weight: 500;
}

/* 리스트 */
.MarkdownEditor ul {
  margin-bottom: 0px;
  line-height: 40px;
}

.MarkdownEditor ol {
  list-style-type: decimal !important;
  list-style-type: lower-alpha !important;
  margin-bottom: 0px;
  line-height: 40px;
}

.MarkdownEditor li {
  list-style-type: disc !important;
  font-size: 20px;
}

/* 문장 */
.MarkdownEditor p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 40px;
}

/* .MarkdownEditor h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MarkdownEditor h2 {
  margin-top: 0px;
  margin-bottom: 0px;
} */

@media (max-width: 750px) {
  /* 수평선 */
  .wmde-markdown hr {
    margin-top: 30px;
  }

  /* 세부항목 타이틀 */
  .wmde-markdown h3 {
    margin-bottom: 18px;
    margin-top: 14px;
    font-size: 18px;
  }

  /* 리스트 */
  .MarkdownEditor ul {
    line-height: 28px;
  }

  .MarkdownEditor ol {
    line-height: 28px;
  }

  .MarkdownEditor li {
    font-size: 16px;
  }

  /* 문장 */
  .MarkdownEditor p {
    font-size: 16px;
    line-height: 28px;
  }
}
