/* Typography */

.ChooseApplyMethod-card-top-title {
  font-size: 2.4rem;
  font-weight: 500;
}

.ChooseApplyMethod-card-bottom button {
  font-size: 2rem;
  color: #4e82e6;
}

.ChooseApplyMethod-card-bottom-info {
  color: #8c8c8c;
  font-size: 1.4rem;
}

/* Layout */
.ChooseApplyMethod-wrapper {
  margin: 0 auto;
  align-items: center;
  min-height: 84vh;
  max-width: 900px;
  display: block;
}

.ChooseApplyMethod-cardWrapper {
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.ChooseApplyMethod-card-middle li {
  color: #505050;
  font-size: 1.6rem;
  letter-spacing: -0.1em;
  /* line-height: 18px; */
}

.ChooseApplyMethod-card-bottom {
  font-size: 1.4rem;
  letter-spacing: -0.1em;
}

/* component */

/* #ChooseApplyMethod-forshadows{
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.72));
} */

.ChooseApplyMethod-title h2 {
  margin: 0 auto;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.1em;
  display: flex;
  justify-content: center;
  margin-top: 3.8rem;
  margin-bottom: 1.8rem;
}

.ChooseApplyMethod-card {
  width: 363px;
  height: 520px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding-right: 4px;

  margin: 40px 40px;
  box-sizing: border-box;
  position: relative;
}

.ChooseApplyMethod-card-top {
  text-align: center;
  position: relative;
}

.ChooseApplyMethod-card-top-title {
  position: absolute;
  display: block;
  width: 116px;
  left: 181.5px;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  /* border: 10px solid #FFFFFF; */
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, -0.1));
}

.ChooseApplyMethod-card-top img {
  margin-top: 34px;
  margin-bottom: 30px;
}

.ChooseApplyMethod-card-top::after {
  content: ' ';
  display: block;
  width: 320px;
  height: 1px;
  position: absolute;
  left: 20px;
  background-color: #f0f0f0;
}

.ChooseApplyMethod-card-middle {
  margin-top: 29px;
  /* position: relative; */
}

.ChooseApplyMethod-card-middle li {
  display: flex;
  padding-left: 20px;
  margin-bottom: 16px;
  line-height: 130%;
}

.ChooseApplyMethod-card-middle li img {
  margin-right: 10px;
}

.ChooseApplyMethod-card-bottom {
  text-align: center;
  position: absolute;
  width: 329px;
  left: 17px;
  bottom: 14px;
}

.ChooseApplyMethod-card-bottom button {
  /* position: absolute; */
  border: 1px solid #f1f1f1;
  box-shadow: 0.5px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 330px;
  height: 50px;
  background: #ffffff;
  cursor: pointer;
  font-size: 2rem;
  /* left: 17px;
    bottom: 42px; */
}

.ChooseApplyMethod-card-bottom p {
  margin-top: 14px;
}

@media (max-width: 1049px) {
  .ChooseApplyMethod-card {
    width: 320px;
    height: 510px;
    margin: 40px 15px;
  }

  .ChooseApplyMethod-card-top-title {
    left: 160px;
  }

  .ChooseApplyMethod-card-top::after {
    width: 280px;
    height: 1px;
    left: 20px;
  }

  .ChooseApplyMethod-card-bottom {
    width: 300px;
    left: 10px;
    bottom: 10px;
  }

  .ChooseApplyMethod-card-middle li {
    padding-left: 24px;
  }

  .ChooseApplyMethod-card-bottom button {
    width: 280px;
    height: 50px;
  }
}

@media (max-width: 750px) {
  .ChooseApplyMethod-cardWrapper {
    display: block;
  }

  .ChooseApplyMethod-title h2 {
    font-size: 2.4rem;
  }

  .ChooseApplyMethod-wrapper {
    min-height: 88vh;
  }

  .ChooseApplyMethod-wrapper {
    flex-direction: column;
  }

  .ChooseApplyMethod-card {
    width: 310px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .ChooseApplyMethod-card-top-title {
    left: 155px;
  }

  .ChooseApplyMethod-card-top::after {
    width: 270px;
    height: 1px;
    left: 20px;
  }

  .ChooseApplyMethod-card-middle li {
    padding-left: 18px;
    margin-bottom: 14px;
  }

  .ChooseApplyMethod-card-bottom {
    left: 4px;
  }

  .ChooseApplyMethod-card-bottom button {
    width: 268px;
    height: 46px;
    font-size: 1.8rem;
  }
}
