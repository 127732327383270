#Navbar_wrapper {
  height: 12vh;
  border-bottom: none;
  position: relative;
  z-index: 999;
  padding-top: 3vh;
  box-sizing: border-box;
}

#Navbar_nav {
  max-width: 1200px;
  padding: 0 2rem;
  margin: 0 auto;
}

@media (max-width: 1049px) {
  #Navbar_nav {
    max-width: 768px;
  }

  #Navbar_hamburger.active {
    position: absolute;
    top: 2vh;
    left: 2rem;
  }
}

@media (max-width: 750px) {
  #Navbar_wrapper {
    /* height: 10vh; */
    padding-top: 1vh;
  }

  #Navbar_nav {
    max-width: 650px;
  }
}
