/* Layout */

.ProfilesectionToggle_right {
  width: 68%;
  height: 84vh;
  margin-left: 4%;
  overflow-y: auto;
}
.ProfilesectionToggle_right::-webkit-scrollbar {
  width: 6px;
  background-color: #f3f3f3;
  border-radius: 10px;
}
.ProfilesectionToggle_right::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 10px;
  height: 3%;
}

/* Component */

.ProfilesectionToggle_right h2 {
  font-size: 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  box-sizing: border-box;
  font-weight: 500;
}

@media (max-width: 1049px) {
  /* Layout */

  .ProfilesectionToggle_right {
    display: none;
  }
}
