/* 대회 신청 목록 필터 */
.CompetitionBracket-filter-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.CompetitionBracket-filter-teaminfo label {
  font-size: 1.4rem;
}

.CompetitionBracket-filter-top-table-column li {
  font-size: 1.4rem;
  font-weight: 600;
}

.CompetitionBracket-filter-top-table-row li {
  font-size: 1.6rem;
  color: #8c8c8c;
}

.CompetitionBracket-filter-top-table-row li p {
  width: 86%;
}

.CompetitionBracket-filter-top-table-row input {
  font-size: 1.6rem;
}

.CompetitionBracket-filter-bottom-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
}

/* Layout */

.CompetitionBracket-filter-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: 84vh;
  box-sizing: border-box;
}

.CompetitionBracket-filter-top {
  position: relative;
  margin-bottom: 40px;
}

.CompetitionBracket-filter-title {
  text-align: center;
  padding: 4vh 0;
}

.CompetitionBracket-filter-teaminfo {
  text-align: center;
  margin-bottom: 30px;
  /* display: inline-block; */
}

.CompetitionBracket-filter-teaminfo label {
  margin-left: 25px;
}

.CompetitionBracket-filter-teaminfo input {
  margin-left: 10px;
}

.CompetitionBracket-filter-teaminfo input::placeholder {
  font-size: 1.4rem;
}
.CompetitionBracket-filter-top-table-row li input::placeholder {
  font-size: 1.4rem;
}

.CompetitionBracket-filter-top-table {
  width: 82%;
  margin: 0 auto;
}

.CompetitionBracket-filter-top-table-child1 {
  width: 40%;
  display: inline-block;
}

.CompetitionBracket-filter-top-table-child2 {
  width: 60%;
  display: inline-block;
}

.CompetitionBracket-filter-top-table-column {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionBracket-filter-top-table-column li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
}

.CompetitionBracket-filter-top-table-row {
  display: table;
  margin: 0 auto;
  width: 100%;
}

.CompetitionBracket-filter-top-table-row li {
  display: table-cell;
  vertical-align: middle;
  width: 16.66%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 2%;
  font-weight: 400;
}

/*Component*/

.CompetitionBracket-filter-teaminfo input {
  width: 170px;
  height: 30px;
  border: 1px solid #e2eaef;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.6rem;
}

.CompetitionBracket-filter-top-table-column li {
  background: #e9f3fd;
  border: 1px solid #e2eaef;
}

.CompetitionBracket-filter-top-table-child1 li:first-child {
  border-radius: 5px 0px 0px 0px;
}

.CompetitionBracket-filter-top-table-child2 li:last-child {
  border-radius: 0px 5px 0px 0px;
}

.CompetitionBracket-filter-top-table-row li {
  border: 1px solid #e2eaef;
  position: relative;
  cursor: pointer;
}

.CompetitionBracket-filter-top-table-row li:first-child {
  border-radius: 0px 0px 0px 5px;
  padding-left: 10px;
}

.CompetitionBracket-filter-top-table-row li input {
  margin-left: -7px;
  padding-left: 7px;
}

.CompetitionBracket-filter-top-table-row li:last-child {
  border-radius: 0px 0px 5px 0px;
}

#CompetitionBracket-filter-top-table-ginogi {
  border-radius: 0px;
}

#CompetitionBracket-filter-top-table-division {
  border-radius: 0px;
}

.CompetitionBracket-filter-top-table-row-dropdown-icon {
  position: absolute;
  right: 5px;
  bottom: 12px;
}

.CompetitionBracket-filter-top-table-row input {
  box-sizing: border-box;
  border: 0;
  border-radius: 0px 0px 0px 5px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
}

#CompetitionBracket-filter-top-table-row-dropdown {
  position: absolute;
  top: 39px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

#CompetitionBracket-filter-top-table-row-dropdown li {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e2eaef;
  border-top: none;
  border-radius: 0px;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #000000;
}

.CompetitionBracket-filter-top-table-row-disable {
  pointer-events: none;
  opacity: 0.7;
  background-color: #cecece;
}

/* 바텀테이블 component */

@media (max-width: 1049px) {
  .CompetitionBracket-filter-wrapper {
    width: 700px;
  }

  .CompetitionBracket-filter-top-table {
    width: 100%;
    margin: 0 auto;
  }

  .CompetitionBracket-filter-button-add {
    right: 0px;
    bottom: -40px;
    height: 36px;
    width: 82px;
    min-height: 36px;
    min-width: 82px;
    font-size: 1.4rem;
  }
}

@media (max-width: 750px) {
  .CompetitionBracket-filter-wrapper {
    width: 90%;
  }

  .CompetitionBracket-filter-top-table-child1 {
    width: 100%;
  }

  .CompetitionBracket-filter-top-table-child2 {
    width: 100%;
  }

  .CompetitionBracket-filter-top-table-child1 li:last-child {
    border-radius: 0px 5px 0px 0px;
  }

  .CompetitionBracket-filter-top-table-child2 li:first-child {
    border-radius: 5px 0px 0px 0px;
  }

  #CompetitionBracket-filter-top-table-ginogi {
    border-radius: 0 0 5px 0px;
  }

  #CompetitionBracket-filter-top-table-division {
    border-radius: 0 0 0 5px;
  }
}

/* Bracket custom-style.css */

.bracket-container {
  border: 1px solid #4e82e5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.bracket-title {
  text-align: center;
  font-size: 20px;
  margin: 10px;
}

.bracket-rapper {
  display: flex;
  overflow-x: auto;
  height: 100%;
  min-height: 500px;
  width: 100%;
}

.bracket-left {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.bracket-right {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
}

.bracket-left-only {
  margin: auto;
}
